import { useState } from "react"

import {
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { IFetchHomes } from "src/data/homes/types/homeQueryTypes"
import { THome, TMaybeHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useFetchHome,
  useFetchHomes,
} from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { IMSelectResult, MSelect } from "src/ui/MSelect/MSelect"

export function SettingHomeTransfer({
  deviceId,
  homeId,
}: {
  deviceId: string
  homeId: string
}) {
  const { t, langKeys } = useTranslate()
  const { org, orgId } = useOrganization()
  const [search, setSearch] = useState("")
  const putDevice = usePutDevice(orgId)
  const fetchHome = useFetchHome({
    orgId: org.id,
    homeId,
  })
  const [selectedHome, setSelectedHome] = useState<TMaybeHome>(
    fetchHome.data ?? null
  )
  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: { name: search },
    options: { select: homesDropdownQuerySelect },
  })

  async function handleSave(
    home: TMaybeHome
  ): TSettingContainerOnSaveReturnType {
    try {
      await putDevice.mutateAsync({
        id: deviceId,
        data: { home: home?.home_id },
      })
      setSelectedHome(home)

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <SettingContainer
      title={t(langKeys.home)}
      description={t(langKeys.device_home_transfer_setting_body)}
      onSave={() => handleSave(selectedHome)}
      displayValue={String(selectedHome?.name)}
      InputComponent={() => (
        <MSelect
          options={fetchHomes.data?.homes ?? []}
          value={selectedHome ? addIdAndNameToHome(selectedHome) : null}
          onSearch={setSearch}
          onSelected={setSelectedHome}
          loading={fetchHomes.isLoading}
          label={t(langKeys.home_plural)}
        />
      )}
    />
  )
}

function homesDropdownQuerySelect(q: IFetchHomes) {
  return { ...q, homes: addIdsToHomes(q.homes) }
}

/** Adds `id` field to home object */
function addIdAndNameToHome(h: THome): THome & IMSelectResult {
  return { ...h, id: h.home_id, name: h.name || h.home_id }
}

function addIdsToHomes(homes: THome[]) {
  return homes.map(addIdAndNameToHome)
}
